<template>
    <div class="certificate-list-content">
        <div class="top">
            <el-breadcrumb separator-class="el-icon-arrow-right" class="genera-breadcrumb">
                <el-breadcrumb-item>评价中心</el-breadcrumb-item>
                <el-breadcrumb-item>证书管理</el-breadcrumb-item>
                <el-breadcrumb-item>未获得证书</el-breadcrumb-item>
            </el-breadcrumb>
            <div class="btn-back">
                <el-button type="primary" plain @click="batchBtn()" v-if="role==='3'">批量生成证书</el-button>
                <el-button type="primary"  @click="backBtn()">返回</el-button>
            </div>
        </div>
        <div class="search-content">
            <el-input v-model="searchCertificateValue"
                      placeholder="请输入搜索内容"
                      slot="prepend"
                      class="w300-input"
                      @keyup.enter.native="certificateSearch">
                <el-button slot="append" icon="el-icon-search" @click="certificateSearch"></el-button>
            </el-input>
        </div>
        <el-table :data="studentListTable" style="width: 100%; flex: 1" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', color: '#666', background: '#eee',fontSize: '16px',height:'60px'}"
                  class="customTable" :cell-style="{fontSize: '16px',color: '#333',height: '70px'}">
            <el-table-column prop="nickname" label="学生名称" ></el-table-column>
            <el-table-column prop="exam_name" label="考试名称" width="600px"></el-table-column>
            <el-table-column prop="student_score" label="分数"  align="center"> </el-table-column>
            <el-table-column label="操作" align="center"  width="120px"  v-if="role==='3'">
                <template slot-scope="scope">
                    <div class="link-list">
                        <el-link type="success" :underline="false" @click="generateCertificate(scope.row)">生成证书</el-link>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
                class="pages-center"
                :current-page="studentPages.currentPageNum"
                :page-size="studentPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="studentPages.total"
                @current-change="studentCurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {getNOStudentCertificateList} from '@/utils/apis'
    export default {
        name: "NoGetCertificateList",
        data(){
            return{
                examId:this.$route.query.exam_id || null,//考试id
                examName:this.$route.query.exam_name || null,//考试名称
                studentId:this.$route.query.no_student_id || null,//未获得证书的学生id
                searchCertificateValue:'',//搜索证书
                studentListTable:[],
                //分页
                studentPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                role:'',//角色id
                selectArr:[],
            }
        },
        mounted(){
            this.getNOStudentCertificateList()
        },
        created() {
            this.role = localStorage.getItem('role');
        },
        methods:{
            // 获取未获得证书列表
            getNOStudentCertificateList(){
                let params = {
                    paging:1,
                    pageSize:this.studentPages.eachPageNum,
                    page:this.studentPages.currentPageNum,
                    exam_id:this.examId,
                    // exam_name:this.examName,
                    // student_id:this.studentId
                }
                if (this.searchCertificateValue) {
                    params.keyword = this.searchCertificateValue
                    params.paging = 1
                }
                getNOStudentCertificateList(params).then((res) => {
                    if (res.data.length === 0){
                        this.studentListTable = []
                    }else if(res.data.data) {
                        this.studentListTable = res.data.data
                    }
                    this.studentPages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //批量生成选择事件
            // selectChange(selection) {
            //     this.selectArr = selection
            // },
            // 批量生成证书
            batchBtn(){
                if (this.studentListTable.length===0){
                    return this.$message.warning('暂无学生')
                }
                this.$confirm('是否批量生成学生证书', '提示消息', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass:'green-message-box',
                    type: 'warning'
                }).then(() => {
                        let routeDate= this.$router.resolve({
                            path: "/evaluationCenter/generateCertificate",
                            query: {
                                batch:1,
                                exam_id: this.examId,
                            },
                        });
                        window.open(routeDate.href, "_blank");
                    // let arr = this.selectArr.map(item => {
                    //     return item.id
                    // })
                    // for (let i = 0; i < this.selectArr.length; i++) {
                    //     let routeDate= this.$router.resolve({
                    //         path: "/evaluationCenter/generateCertificate",
                    //         query: {
                    //             exam_id: this.examId,
                    //             student_id: this.selectArr[i].student_id,
                    //             no_student_id: this.studentId,
                    //             exam_name: this.examName,
                    //             nickname: this.selectArr[i].nickname,
                    //             id_num: this.selectArr[i].id_num,
                    //             avatar: this.selectArr[i].avatar || undefined,
                    //         },
                    //     });
                    //     window.open(routeDate.href, "_blank");
                    // }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消批量生成'
                    });
                })
            },
            // 返回
            backBtn(){
                if(this.role === '2'){
                    this.$router.push({
                        path:'/school/certificate/index'
                    })
                }else {
                    this.$router.push({
                        path:'/evaluationCenter/certificateManagement/index'
                    })
                }
            },
            //搜索事件
            certificateSearch(){
                this.getNOStudentCertificateList()
            },
            //生成证书
            generateCertificate(row){
                let routeDate = this.$router.resolve({
                    path: '/evaluationCenter/generateCertificate',
                    query:{
                        exam_id:this.examId,
                        student_id:row.student_id,
                        // no_student_id:this.studentId,
                        // exam_name:this.examName,
                        nickname:row.nickname,
                        id_num:row.id_num,
                        avatar:row.avatar||undefined,
                    }
                })
                window.open(routeDate.href,'_blank')
            },
            //切换分页
            studentCurrentChange(val){
                this.studentPages.currentPageNum = val;
                this.getNOStudentCertificateList()
            }
        }
    }
</script>
<style scoped lang="scss">
    .certificate-list-content{
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 0 20px;
        .top{
            margin: 20px 0;
            position: relative;
            .btn-back{
                position: absolute;
                top: -12px;
                right: 20px;
            }
        }
        .search-content{
            margin-bottom: 20px;
        }
        ::v-deep .el-table th>.cell{
            padding-right: 0;
        }
        ::v-deep .el-table-column--selection .cell{
            padding-right: 0;
        }
    }
</style>